import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function DownloadProgressIcon() {
  const progress = useSelector((state) => state.counter.downloadProgress);
 //const progress = 100;
  const authID = useSelector((state) => state.counter.id);
  const iDB = useSelector((state) => state.counter.iDB);

  const [downloadCompleted, setDownloadCompleted] = useState(true);

  useEffect(() => {
    const fetchKeys = async () => {
      const iDBKeys = await iDB.keys();
      const downloadCompleted = iDBKeys.length > 17000;
      setDownloadCompleted(downloadCompleted);
    };
    fetchKeys();
  }, [iDB]);

  if (!downloadCompleted && (authID.u || authID.i)) {
 
    const isDownloading = progress > 0 && progress < 100;
    const isComplete = progress === 100;


    let fillColor;

    if (progress === 0) {
        fillColor = "rgb(110, 111, 113)";
    } else if (isComplete) {
        fillColor = "#10b981";
    } else  {
        fillColor = "#3b82f6";
    }

    return (
      <div
        className="download-progress-icon"
         style={{top:isComplete?"6px":"12px"}}
      >
        <p
          style={{
            margin: 0,
            marginRight: "8px",
            fontFamily: "DINNeuzeitGrotesk-Light",
            fontSize: "13px",
            color: "#6e6f71",
            position: "absolute",
            
          }}
          className="weblink"
        >
          Available Offline
         
          <svg
          width={isComplete?"25":"18"}
          height={isComplete?"25":"18"}
          viewBox="0 0 24 24"
          style={{
            font:"bold",
            alignSelf: "center",
            animation: isDownloading ? "arrowDownload 1s infinite" : "none",
            color: fillColor,
            backgroundColor: "transparent", 
            paddingTop: isComplete?"8px":"2px" ,
            position: "realtive",
          }}
        >
          {isComplete ? (
              <g transform="translate(12,12) scale(1.5) translate(-12,-12)">
              <path
                d="M5 13l4 4L19 7"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
              />
              </g>
            ) : (
              <path
                d="M12 4v9m0 0l-4-4m4 4 4-4m-4 4v5M20 20H4"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            )}
        </svg>
        <span
          style={{ 
            display:"block",
            width: "100px",          
            backgroundColor: "#e5e7eb", 
            height: "4px",
            borderRadius: "2px",
            position: "realtive",
            top: "12px",
          }}
        >
          <span
            style={{
              display:"block",
              width: `${progress}%`,      
              backgroundColor: fillColor, 
              height: "100%",
              borderRadius: "2px",
              transition: "width 0.3s ease", 
            }}
          />
        </span>
        </p>

       
        
      </div>
    );
  } else {
    return null;
  }
}