import React, { useState, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';
import {
  saneWeight,
  saneAge,
  rounding,
  initialise,
  saneSerum,
  saneHeight,
} from './utils';
import ccbwcalc_formula from './images/etg_abg15_appendix_figure1_adult-renal-function.png'


const CcbwCalc = (props) => {
  const inputHeightRef = useRef(null);
  const inputWeightRef = useRef(null);
  const inputAgeRef = useRef(null);
  const inputSerumCreatinineRef = useRef(null);
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [age, setAge] = useState('');
  const [sex, setSex] = useState('Male');
  const [creatinineValue, setCreatinineValue] = useState('');
  const [serumUnit, setSerumUnit] = useState('0.001|0');
  const [creatinineEstimate, setCreatinineEstimate] = useState('');
  const [dosingDerivation, setDosingDerivation] = useState('');
  const [errors, setErrors] = useState({});

  const calculate = () => {
    setCreatinineEstimate('');
    setDosingDerivation('');

    let errors = {};
    if (!height) {
      errors = { ...errors, height: 'Please enter a value for height.' }
      inputHeightRef.current.focus();
    } else {
      let height_check = saneHeight(height);
      if (height_check !== true) {
        errors = { ...errors, height: height_check }
      }
    }

    if (!weight) {
      errors = { ...errors, weight: 'Please enter a value for weight.' }
      inputWeightRef.current.focus();
    }

    if (saneWeight(weight) !== true) {
      errors = { ...errors, weight: saneWeight(weight) }
    }

    if (!age) {
      errors = { ...errors, age: 'Please enter a value for age.' }
      inputAgeRef.current.focus();
    }

    if (saneAge(age) !== true) {
      errors = { ...errors, age: saneAge(age) }
    }

    if (!creatinineValue) {
      errors = { ...errors, creatinineValue: 'Please enter a value for creatinine.' }
      inputSerumCreatinineRef.current.focus();
    } else {
      let creatinine_error = saneSerum(creatinineValue);
      if (creatinine_error !== true) {
        errors = { ...errors, creatinineValue: creatinine_error }
      }
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      console.log(errors);
      return;
    }
    const ibw = idealBodyWgt();
    const abw = adjustedBodyWgt();
    const bmi = weight / Math.pow(height / 100, 2);
    const calcWeight = weightCatCalc(bmi, abw, ibw);
    const clear = createCalc(calcWeight);
    createRecoText(clear, ibw, bmi);
  }

  const idealBodyWgt = () => {
    let weightVal, idealWeight, mOver;

    if (sex === 'Male') {
      weightVal = 50;
    } else {
      weightVal = 45.5;
    }

    if (height <= 152) {
      idealWeight = weightVal;
    } else {
      mOver = height - 152;
      idealWeight = weightVal + (.90 * mOver);
    }

    const ibw = rounding(idealWeight, 1);
    return (ibw / 1);
  }

  const adjustedBodyWgt = () => {
    const ibw = idealBodyWgt(height, sex);
    let abw = ibw + (0.4 * (weight - ibw));
    abw = rounding(abw, 1);
    return (abw / 1);
  }

  const weightCatCalc = (bmi, abw, ibw) => {
    let calcWeight;
    if (bmi < 18.5) {
      calcWeight = weight;
    } else if (bmi > 25) {
      calcWeight = abw;
    } else {
      calcWeight = ibw;
    }

    return (calcWeight);
  }

  const reset = () => {
    setHeight('');
    setWeight('');
    setAge('');
    setSex('Male');
    setCreatinineValue('');
    setSerumUnit('0.001|0');
    setCreatinineEstimate('');
    setDosingDerivation('');
    setErrors({});
  }

  const createCalc = (calcWeight) => {
    const unitParts = serumUnit.split('|');
    const cr = creatinineValue * parseFloat(unitParts[0]) + parseFloat(unitParts[1]);

    let clear = ((140 - age) * calcWeight) / (814 * cr);
    if (sex === 'Female') {
      clear *= 0.85;
    }

    if (unitParts[0] === 1) {
      clear = rounding(clear, 0);
    } else {
      clear = rounding(clear, 2);
    }

    const decimalPart = clear - Math.floor(clear);
      if (decimalPart < 0.50) {
        clear = Math.floor(clear);
      } else {
        clear = Math.ceil(clear);
      }

      setCreatinineEstimate(`${clear} mL/min`);
      return clear;
      }

  const calcWeightRange = (bmi) => {
    if (bmi < 18.5) {
      return 'underweight';
    } else if (bmi > 25) {
      return 'overweight';
    }

    return 'normal weight';
  }

  const createRecoText = (clear, lbw, bmi) => {
    let criteria, type;
    const weightRange = calcWeightRange(bmi);

    switch (weightRange) {
      case 'overweight':
        type = 'adjusted body weight';
        criteria = 'BMI above 25';
        break;
      case 'underweight':
        type = 'actual body weight';
        criteria = 'BMI below 18.5';
        break;
      case 'normal weight':
        type = 'ideal body weight';
        criteria = 'BMI 18.5 to 25';
        break;
      default:
        type = 'ideal body weight';
        criteria = 'BMI 18.5 to 25';
    }
    if (clear > 120) {
      clear = "more than 120 "
    }  
    const txt = `
    <p><strong>How this creatinine clearance was derived</strong></p>The creatinine clearance ${clear} mL/min has been calculated using the patient's ${type}.
      ${initialise(type)} was used because the patient is classified as ${weightRange} based on a BMI of ${rounding(bmi, 1)} 
      kg/m<sup>2</sup>.`;

    setDosingDerivation(txt);
  }

  return (
    <div>
      <div>
        <h4 style={{fontWeight: 'bold'}} >Creatinine clearance calculator for adults</h4>
        <p>
          This creatinine clearance calculator should only be used for patients 18 years and older.
          <br /><br />
          It provides an estimate of creatinine clearance using the Cockcroft-Gault formula.
          <br /><br />
          <img src={ccbwcalc_formula} id="ccbwcalc_formula" alt="Creatine clearance formula" border="0" />
          <br /><br />
          Although actual body weight is entered into the calculator, the weight used in the calculation of creatinine clearance depends
          on whether the patient (based on their <HashLink className="calc-links" to="/viewTopic?etgAccess=true&guidelinePage=Cardiovascular&topicfile=cardiovascular-disease-risk-stratification&guidelinename=auto&sectionId=c_CVG_Excess-body-weight-obesity-and-atherosclerotic-cardiovascular-disease-risktopic_6#c_CVG_Excess-body-weight-obesity-and-atherosclerotic-cardiovascular-disease-risktopic_6">Body Mass Index [BMI]</HashLink>) is underweight, healthy weight or overweight.
          <br /><br />
          For patients who are underweight (BMI less than 18.5 kg/m<sup>2</sup>), actual body weight is used in the calculator.
          <br /><br />
          For patients who are healthy weight (BMI 18.5 to less than 25 kg/m<sup>2</sup>), <HashLink className="calc-links" to="/calculator/ibwcalc">ideal body weight</HashLink> is used in the calculator.
          <br /><br />
          For patients who are overweight (BMI 25 kg/m<sup>2</sup> or more), <HashLink className="calc-links" to="/viewTopic?etgAccess=true&guidelinePage=Antibiotic&topicfile=bartonella-infections&guidelinename=auto&sectionId=c_ABG_Aminoglycoside-use-in-special-patient-groups_topic_5#c_ABG_Aminoglycoside-use-in-special-patient-groups_topic_5">adjusted body weight</HashLink> is used in the calculator.
          <br /><br />
          The calculator does not accurately predict creatinine clearance in patients who have unstable renal function (eg patients in intensive care, patients with acute renal impairment, patients with febrile neutropenia); a measured (urinary) creatinine clearance is most accurate in this situation.
          <br /><br />
          The calculator should not be used for patients who have low muscle mass (eg cachectic patients).
        </p>
        <div className="form-horizontal form-content-calc">
          <div className="col-xs-12 col-lg-6">
            <div className="form-group mTop12px">
              <label className="control-label col-lg-5 col-xs-4" htmlFor="height">Height:</label>
              <div className="col-xs-7" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="col-lg-9 noLeftPadding noRightPadding">
                  <input
                    type="number"
                    id="height"
                    className="form-control"
                    value={height}
                    ref={inputHeightRef}
                    min="0"
                    onChange={(event) => {
                      setHeight(event.target.value);
                      setErrors((prev) => ({ ...prev, height: '' }));
                    }}
                  />
                </div>
                <div className="col-lg-3" style={{ paddingLeft: "20px" }}><div className="row">cm</div></div>
              </div>
            </div>
            
            <div className='row'>
              <div className="col-xs-5" />
              <div className="col-xs-7">
                {errors.height && <div className="invalid-feedback">{errors.height}</div>}
              </div>
            </div>

            <div className="form-group mTop12px">
              <label className="control-label col-lg-5 col-xs-4" htmlFor="height">Actual body weight:</label>
              <div className="col-xs-7 align-center">
                <div className="col-lg-9 noLeftPadding noRightPadding">
                  <input
                    type="number"
                    size="5"
                    className="form-control"
                    value={weight}
                    ref={inputWeightRef}
                    min="0"
                    id="c2kg"
                    onChange={(event) => {
                      setWeight(event.target.value);
                      setErrors((prev) => ({ ...prev, weight: '' }));
                    }}
                  />
                </div>
                <div className="col-lg-3" style={{ paddingLeft: "20px" }}><div className="row">kg</div></div>
              </div>
            </div>
            
            <div className='row'>
              <div className="col-xs-5" />
              <div className="col-xs-7">
                {errors.weight && <div className="invalid-feedback">{errors.weight}</div>}
              </div>
            </div>

            <div className="form-group mTop12px">
              <label className="control-label col-lg-5 col-xs-4" htmlFor="age">Age:</label>
              <div className="col-xs-7" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="col-lg-9 noLeftPadding noRightPadding">
                  <input
                    type="number"
                    id="age"
                    size="5"
                    className="form-control"
                    value={age}
                    ref={inputAgeRef}
                    min="0"
                    onChange={(event) => {
                      setAge(event.target.value);
                      setErrors((prev) => ({ ...prev, age: '' }));
                    }}
                  />
                </div>
                <div className="col-lg-3" style={{ paddingLeft: "20px" }}><div className="row">years</div></div>
              </div>
            </div>

            
            <div className='row'>
              <div className="col-xs-5" />
              <div className="col-xs-7">
                {errors.age && <div className="invalid-feedback">{errors.age}</div>}
              </div>
            </div>


            <div className="form-group mTop12px item-center">
              <label className="control-label col-xs-5">Sex:</label>
              <div className="col-xs-7">
                <label className="radio-inline">
                  <input className='big-checkbox' type="radio" checked={sex === 'Male'} value="Male" onChange={(event) => setSex(event.target.value)} />
                  <div style={{padding: "0.5rem"}}> Male </div>
                </label>
              
                <label className="radio-inline">
                  <input className='big-checkbox' type="radio" checked={sex === 'Female'} value="Female" onChange={(event) => setSex(event.target.value)} />
                  <div style={{padding: "0.5rem"}}> Female </div>
                </label>
              </div>
            </div>

            <div className="form-group mTop12px">
              <label className="control-label col-lg-5 col-xs-4" htmlFor="serumCreatinine" data-bs-toggle="tooltip" title="Between 5 and 10000" >Serum creatinine:</label>
              <div className="col-xs-7" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="col-lg-9 noLeftPadding noRightPadding">
                  <input
                    type="number"
                    id="serumCreatinine"
                    size="5"
                    className="form-control"
                    value={creatinineValue}
                    ref={inputSerumCreatinineRef}
                    min="0"
                    onChange={(event) => {
                      setCreatinineValue(event.target.value);
                      setErrors((prev) => ({ ...prev, creatinineValue: '' }));
                    }}
                  />
                </div>
                <div className="col-lg-3" style={{ paddingLeft: "20px" }}><div className="row">micromoles/L</div></div>
              </div>
            </div>

            
            <div className='row'>
              <div className="col-xs-5" />
              <div className="col-xs-7">
                {errors.creatinineValue && <div className="invalid-feedback">{errors.creatinineValue}</div>}
              </div>
            </div>

            <div className="form-group mTop12px" style={{ display: 'none' }}>
              <select className="form-control" name="SerumUnit" defaultValue={serumUnit}>
                <option value="0.001|0">micromoles/L</option>
                <option value="1|0">millimoles/L</option>
              </select>
            </div>
            <div className="form-group mTop12px">
              <div className="col-xs-12 btncalc-container" style={{ display: 'flex', justifyContent: 'center' }}>
                <button className="btn btn-info btn-sm borderNone btn-calc btn-custom" id="ccbwcalc_compute" onClick={() => calculate()}>Calculate</button>
                <button className="btn btn-default btn-sm borderNone btn-clr btn-custom" onClick={() => reset()}>Clear all</button>
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-lg-6 aid-result text-center" style={{ background: 'wheat' }}>
            <label htmlFor='initialDose'><strong>Creatinine clearance</strong></label>
            <div className="mgtp10px">
              <input
                type="text"
                className="form-control aid-dose-width center text-center"
                id="c2estimate"
                value={creatinineEstimate}
                readOnly
              />
            </div>
            <div className="mgtp10px">
              <p dangerouslySetInnerHTML={{ __html: dosingDerivation }}></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CcbwCalc;
