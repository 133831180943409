import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { rounding, saneAge, saneHeight, saneSerum, saneWeight } from './utils';

const GidCalc = (props) => {
  const { currentPath } = props;
  const inputHeightRef = useRef(null);
  const inputActualBodyWeightRef = useRef(null);
  const inputAgeRef = useRef(null);
  const inputSerumCreatinineRef = useRef(null);
  const [critical, setCritical] = useState(false);
  const [renalUnknown, setRenalUnknown] = useState(false);
  const [height, setHeight] = useState('');
  const [actualBodyWeight, setActualBodyWeight] = useState('');
  const [age, setAge] = useState('');
  const [sex, setSex] = useState('Male');
  const [creatinineValue, setCreatinineValue] = useState('');
  const [serumUnit, setSerumUnit] = useState('0.001|0');
  const [initialDose, setInitialDose] = useState('');
  const [serumIsShow, setSerumIsShow] = useState(true);
  const [dosingFrequency, setDosingFrequency] = useState('');
  const [adviseFrequency, setAdviseFrequency] = useState('');
  
  const [dosingDerivation, setDosingDerivation] = useState('');
  const [errors, setErrors] = useState({});
  const [bmi, setBmi] = useState(0);

  
  useEffect(() => {
    reset();
  }, [currentPath]);

  useEffect(() => {
    setErrors((prev) => ({ ...prev, creatinine: '' }));
  }, [serumIsShow]);

  const calculate = () => {
    /*alert(height);
    alert(actualBodyWeight);
    alert(age);*/
   
    setInitialDose('');
    setDosingFrequency('');
    setAdviseFrequency('');
    setDosingDerivation('');

    let errors = {};
    if (!height) {
      errors.height = "Please enter a value for height.";
      //inputHeightRef.current.classList.add('highlight');
      inputHeightRef.current.focus();
    } else {
    let height_check = saneHeight(height);
    if (height_check !== true) {
      errors.height = height_check;
    }}

    if (!actualBodyWeight) {
      errors.weight = "Please enter a value for weight.";
      inputActualBodyWeightRef.current.focus();
    } else {
    let weight_check = saneWeight(actualBodyWeight);
    if (weight_check !== true) {
      errors.weight = weight_check;
    }}

    if (!age) {
      errors.age = "Please enter a value for age.";
    } else {
      let age_check = saneAge(age);
      if (age_check !== true) {
        errors.age = age_check;
      }
    }

    if (serumIsShow && !creatinineValue) {
      errors.creatinine = "Please enter a value for creatinine.";
      inputSerumCreatinineRef.current.focus();
    } else if(serumIsShow) {
    let serum = saneSerum(creatinineValue);
    if (serum !== true) {
      errors.creatinine = serum;
    }}

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    const bmi = actualBodyWeight / Math.pow(height / 100, 2);
    const ibw = idealBodyWgt();
    setBmi(bmi);
    const abw = adjustedBodyWgt();
    const calcWeight = weightCatCalc(bmi, abw, ibw);
    const lbw = leanBodyWeight(bmi);
    const cr = createCalcHw(calcWeight);
    const dose = doseCalc(cr,lbw);
    //console.log(dose.cidnoroundd);
    createRecoText(dose, cr, lbw, bmi);
  }

  const idealBodyWgt = () => {
    let weightVal, idealWeight, mOver;

    if (sex === 'Male') {
      weightVal = 50;
    } else {
      weightVal = 45.5;
    }

    if (height <= 152) {
      idealWeight = weightVal;
    } else {
      mOver = height - 152;
      idealWeight = weightVal + (.90 * mOver);
    }

    const ibw = rounding(idealWeight, 1);
    return (ibw / 1);
  }

  const adjustedBodyWgt = () => {
    const ibw = idealBodyWgt(height, sex);
    let abw = ibw + (0.4 * (actualBodyWeight - ibw));
    abw = rounding(abw, 1);
    return (abw / 1);
  }

  const weightCatCalc = (bmi, abw, ibw) => {
    let calcWeight;
    if (bmi < 18.5) {
      calcWeight = actualBodyWeight;
    } else if (bmi > 25) {
      calcWeight = abw;
    } else {
      calcWeight = ibw;
    }

    return (calcWeight);
  }

  const leanBodyWeight = (bmi) => {
    let lbw = 0;
    if (sex === 'Male') {
      lbw = (9270 * actualBodyWeight) / (6680 + (216 * bmi));
    } else {
      lbw = (9270 * actualBodyWeight) / (8780 + (244 * bmi));
    }

    return (lbw / 1);
  }

  const createCalcHw = (weight) => {
    const unitParts = serumUnit.split('|');
    const cr = creatinineValue * parseFloat(unitParts[0]) + parseFloat(unitParts[1]);
    let clear = ((140 - age) * weight) / (814 * cr);

    if (sex === 'Female') {
      clear = clear * 0.85;
    }

    if (unitParts[0] === 1) {
      clear = rounding(clear, 0);
    } else {
      clear = rounding(clear, 2);
    }
    
    const decimalPart = clear - Math.floor(clear);

    if (decimalPart < 0.50) {
      clear = Math.floor(clear);
    } else {
      clear = Math.ceil(clear);
    }

    return clear;
  }

  const doseCalc = (clear, lbw) => {
    let cid;
    let cidnoround;
    let id
    if (currentPath === 'Amikacin') {
      if (clear > 60 || critical) {
        cid = rounding(30 * lbw, 0);
        cidnoround = 30 * lbw;
      } else if (clear < 40) {
        cid = rounding(15 * lbw, 0);
        cidnoround = 15 * lbw;
      } else {
        cid = rounding(20 * lbw, 0);
        cidnoround = 20 * lbw;
      }
      

      if (cid > 2500) {
        cid = 2500;
      }
          id = Math.floor(cid / 125) * 125;
      if (clear >= 40 && clear <= 60 && id > 1625) {
          id =1625;
      } else if (clear < 40 && id > 1250 ) {
          id =1250;
      } else if (clear > 60 &&  id > 2500 ) {
          id =2500;
      }else {
          id = Math.floor(cid / 125) * 125;
      } 
    
      setInitialDose(`${id} mg`);
   } else {
      if (clear > 60 || critical) {
        cid = rounding(7 * lbw, 0);
        cidnoround = 7 * lbw;
      } else if (clear < 40) {
        cid = rounding(4 * lbw, 0);
        cidnoround= 4 * lbw;
      } else {
        cid = rounding(5 * lbw, 0);
        cidnoround = 5 * lbw;
      }

      if (cid > 680) {
        cid = 680;
      }
         id = Math.floor(cid / 20) * 20;
         //console.log(`clear: ${clear}, id: ${id}`); // Debugging statement
      if (clear >= 40 && clear <= 60  && id > 480) {
          id =480;
      } else if (clear < 40 &&  id > 380) {
          id =380;
      } else if (clear > 60  && id > 680) {
          id =680;
      } else {
          id = Math.floor(cid / 20) * 20;
      } 
      setInitialDose(`${id} mg`);
    }
    
    let cidnoroundd
    const decimalPartof = cidnoround - Math.floor(cidnoround);
    //alert(dose.cidnoroundd);
    if (decimalPartof < 0.50) {
      cidnoroundd = Math.floor(cidnoround);
    } else {
      cidnoroundd = Math.ceil(cidnoround);
    }

    //let cidnoroundd = Math.ceil(cidnoround);
     
    return {cid,cidnoroundd};
  }

  const createRecoText = (dose, clear, lbw, bmi) => {
    

    const isAmikacin = currentPath === 'Amikacin';
    const roundTo = isAmikacin ? 125 : 20;
    const doseoff = isAmikacin ? '30 mg/kg' : '7 mg/kg';
    const max_dose = isAmikacin ? 2500 : 680;
    const getDRate = () => {
      if (clear > 60 || critical) return isAmikacin ? '30 mg/kg' : '7 mg/kg';
      if (clear < 40) return isAmikacin ? '15 mg/kg' : '4 mg/kg';
      return isAmikacin ? '20 mg/kg' : '5 mg/kg';
    };
    
    const dRate = getDRate();
    let txt = '';
      
    if (dose.cidnoroundd > max_dose) {
      txt = `The calculated initial dose without rounding is ${dose.cidnoroundd} mg which exceeds the maximum dose. The maximum dose is recommended instead. <br><br> The ${currentPath.toLowerCase()} dose of ${dose.cidnoroundd} mg corresponds to a dose of ${doseoff} based on a lean body weight of ${rounding(lbw, 1)} kg.<br><br>${dRate} is recommended because `;
    } else {
      const roundedDose = Math.floor(parseFloat(dose.cid) / roundTo) * roundTo;

     /* txt = `<br>The calculated initial dose without rounding is ${dose.cidnoroundd} mg. <br>which exceeds the maximum dose. The maximum dose is recommended instead.<br><br>The ${currentPath.toLowerCase()} dose of ${roundedDose} mg corresponds to a dose of <br> ${dRate} based on a lean body weight of the patient is ${rounding(lbw, 1)} kg.<br><br>${dRate} is recommended because `;*/

      txt = `<br>The calculated initial dose without rounding is ${dose.cidnoroundd} mg. <br>For practicality, this dose has been rounded down to the nearest ${roundTo} mg. For accuracy, rounding was only applied to the final value.<br><br>The ${currentPath.toLowerCase()} dose of ${dose.cidnoroundd} mg corresponds to a dose of <br> ${dRate} based on a lean body weight of ${rounding(lbw, 1)} kg.<br><br>${dRate} is recommended because `;
    }
  
    if (critical) {
      txt += 'the patient is critically ill.';
    } else if (renalUnknown) {
      txt += 'the patient is not critically ill, and while their kidney function is unknown it is not presumed to be impaired.';
    } else {
      if (clear > 120) {
        txt += `the patient is not critically ill and their creatinine clearance is more than 120 mL/min (based on adjusted body weight).`;
      }else{
        txt += `the patient is not critically ill and their creatinine clearance is ${clear} mL/min (based on adjusted body weight).`;
      }
      
    }
  
    setDosingDerivation(txt);
    setAdviseFrequency ('<strong>Administration advice: </strong> <br> See <a href="/viewTopic?etgAccess=true&guidelinePage=Antibiotic&topicfile=bartonella-infections&guidelinename=auto&sectionId=c_ABG_Aminoglycoside-dosing-and-administration_topic_2#c_ABG_Aminoglycoside-dosing-and-administration_topic_2"> Aminoglycoside administration advice.</a>');
    setDosingFrequency(
      critical ? 
           `A single dose of ${currentPath.toLowerCase()} may be given; seek expert advice for subsequent management because the patient is critically ill. ` : 
        bmi > 35 ? 
          `A single dose of ${currentPath.toLowerCase()} may be given; seek expert advice for subsequent management because the patient has a body mass index (BMI) of 35 kg/m<sup>2</sup> or more. For a discussion of different approaches to dosing in adults with obesity, see <a href='/viewTopic?etgAccess=true&guidelinePage=Antibiotic&topicfile=aminoglycoside-use-principles&guidelinename=Antibiotic&sectionId=toc_d1e2863#toc_d1e2863'> Aminoglycoside use in adults who are obese </a>.` :
          clear > 60 ? 
            `A maximum of 3 doses of ${currentPath.toLowerCase()} can be given at 0, 24, and 48 hours.`
            : `A single dose of ${currentPath.toLowerCase()} may be given; seek expert advice for subsequent management.`
    );
  };

  const reset = () => {
    setHeight('');
    setActualBodyWeight('');
    setAge('');
    setSex('Male');
    setCreatinineValue('');
    setSerumUnit('0.001|0');
    setInitialDose('');
    setDosingFrequency('');
    setAdviseFrequency('');
    setDosingDerivation('');
    setSerumIsShow(true);
    setRenalUnknown(false);
    setBmi(0);
    setCritical(false); 
  }
  
  return (
    <div>
      <div>
        <h4 style={{ fontWeight: 'bold' }} >{currentPath} initial dose calculator for adults</h4>
        <p>
          The calculator below provides an initial {currentPath.toLowerCase()} dose for patients older than 18 years. This calculator cannot be used to calculate the dose for surgical prophylaxis or synergistic therapy. <br /><br />
          The dose is calculated using the patient’s lean body weight. For patients without septic shock and not requiring intensive care support, the dose depends on their creatinine clearance (CrCl):
          
        </p>
        {
          currentPath === 'Amikacin' ? (
            <ul>
              <li>
                CrCl more than 60 mL/min: 30 mg/kg up to 2.5 g
              </li>
              <li>
                CrCl 40 to 60 mL/min: 20 mg/kg up to 1.625 g
              </li>
              <li>
                CrCl less than 40 mL/min: 15 mg/kg up to 1.25 g.
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                CrCl more than 60 mL/min: 7 mg/kg up to 680 mg
              </li>
              <li>
                CrCl 40 to 60 mL/min: 5 mg/kg up to 480 mg
              </li>
              <li>
                CrCl less than 40 mL/min: 4 mg/kg up to 380 mg.
              </li>
            </ul>
          )
        }
        <p>
        {
          currentPath === 'Amikacin' ? (
            <p>For patients with septic shock or requiring intensive care support, a dose of 30 mg/kg is used regardless of kidney function. </p>
          ) : (
             <p>For patients with septic shock or requiring intensive care support, a dose of 7 mg/kg is used regardless of kidney function. </p>
          )
        }
          For information on the selection of these parameters, and how to calculate them, see <Link className="calc-links" to="/viewTopic?etgAccess=true&guidelinePage=Antibiotic&topicfile=c_ABG_Principles-of-aminoglycoside-use_topic_1&guidelinename=auto&sectionId=c_ABG_Principles-of-aminoglycoside-use_topic_1#c_ABG_Principles-of-aminoglycoside-use_topic_1">
          Principles of aminoglycoside use</Link>, <Link to="/calculator/ccbwcalc">Creatinine clearance calculator for adults</Link> and <Link to="/calculator/lbwcalc">Lean body weight calculator</Link>.<br /><br />
          The calculator may be used for all adult patients without a contraindication for aminoglycoside use. Contraindications, as well as precautions for use, are listed in <a className="calc-links" href="/viewTopic?etgAccess=true&guidelinePage=Antibiotic&topicfile=bartonella-infections&guidelinename=auto&sectionId=c_ABG_Principles-of-aminoglycoside-use_topic_4#c_ABG_Principles-of-aminoglycoside-use_topic_4">Figure: Aminoglycoside contraindications and precautions</a>. A single dose of aminoglycoside is generally safe; however, subsequent dosing requires careful consideration in patients:
        </p>
        <ul>
          <li>
            with chronically impaired kidney function (adults with a creatinine clearance less than 40 mL/minute) who are not receiving long-term dialysis
          </li>
          <li>
            with rapidly deteriorating kidney function
          </li>
          <li>
            who are frail and elderly (eg 80 years or older).
          </li>
        </ul>
        <div className="form-horizontal form-content-calc">
          <div className="col-xs-12 col-lg-6">

            <div className="form-group mTop12px item-center">
              <label className="control-label col-xs-5">Septic shock or requires intensive care support:</label>
              <div className="col-xs-7">
                <label className="radio-inline">
                  <input type="radio" checked={critical} value="true" className='big-checkbox' onChange={(event) => setCritical(true)} />
                  <div style={{ padding: "0.5rem" }}> Yes </div>
                </label>
                <label className="radio-inline">
                  <input type="radio" checked={!critical} value="false" className='big-checkbox' onChange={(event) => setCritical(false)} />
                  <div style={{ padding: "0.5rem" }}> No </div>
                </label>
              </div>
            </div>

            <div className="form-group mTop12px item-center">
              <label className="control-label col-xs-4" htmlFor="height">Height:</label>
              <div className="col-xs-7" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="col-lg-9 noLeftPadding noRightPadding">
                  <input
                    type="number"
                    id="height"
                    className="form-control"
                    value={height}
                    ref={inputHeightRef}
                    min="0"
                    onChange={(event) => {
                      setHeight(event.target.value);
                      setErrors((prev) => ({ ...prev, height: '' }));
                    }}
                  />
                </div>
                <div className="col-lg-3 col-sm-12" style={{ paddingLeft: "20px" }}><div className="row">cm</div> </div>
              </div>
            </div>


            <div className='row'>
              <div className="col-xs-4" />
              <div className="col-xs-7">
                {errors.height && <div className="invalid-feedback">{errors.height}</div>}
              </div>
            </div>

            <div className="form-group mTop12px item-center">
              <label className="control-label col-xs-4 no-wrap" htmlFor="actualBodyWeight">Actual body weight:</label>
              <div className="col-xs-7" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="col-lg-9 noLeftPadding noRightPadding">
                  <input
                    type="number"
                    id="actualBodyWeight"
                    min="0"
                    className="form-control"
                    value={actualBodyWeight}
                    onChange={(event) => {
                      setActualBodyWeight(event.target.value);
                      setErrors((prev) => ({ ...prev, weight: '' }));
                    }}
                    ref={inputActualBodyWeightRef}
                  />
                </div>
                <div className="col-lg-3" style={{ paddingLeft: "20px" }}><div className="row">kg</div></div>
              </div>
            </div>


            <div className='row'>
              <div className="col-xs-4" />
              <div className="col-xs-7">
                {errors.weight && <div className="invalid-feedback">{errors.weight}</div>}
              </div>
            </div>

            <div className="form-group mTop12px item-center">
              <label className="control-label col-xs-4" htmlFor="age">Age:</label>
              <div className="col-xs-7" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="col-lg-9 noLeftPadding noRightPadding">
                  <input
                    type="number"
                    id="age"
                    size="5"
                    className="form-control"
                    value={age}
                    ref={inputAgeRef}
                    min="0"
                    onChange={(event) => {
                      setAge(event.target.value);
                      setErrors((prev) => ({ ...prev, age: '' }));
                    }}
                  />
                </div>
                <div className="col-lg-3" style={{ paddingLeft: "20px" }}><div className="row">years</div></div>
              </div>
            </div>

            <div className='row'>
              <div className="col-xs-4" />
              <div className="col-xs-7">
                {errors.age && <div className="invalid-feedback">{errors.age}</div>}
              </div>
            </div>

            <div className="form-group mTop12px item-center">
              <label className="control-label col-xs-5">Sex:</label>
              <div className="col-xs-7">
                <label className="radio-inline">
                  <input className='big-checkbox' type="radio" checked={sex === 'Male'} value="Male" onChange={(event) => setSex(event.target.value)} />
                  <div style={{ padding: "0.5rem" }}> Male </div>
                </label>

                <label className="radio-inline">
                  <input className='big-checkbox' type="radio" checked={sex === 'Female'} value="Female" onChange={(event) => setSex(event.target.value)} />
                  <div style={{ padding: "0.5rem" }}> Female </div>
                </label>
              </div>
            </div>
            <div className="form-group mTop12px item-center">
              <label className="control-label col-xs-4 no-wrap" htmlFor="serumCreatinine" data-bs-toggle="tooltip" title="Between 5 and 10000">Serum creatinine:</label>
              <div className="col-xs-7" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="col-lg-9 noLeftPadding noRightPadding">
                  <input
                    type="number"
                    id="serumCreatinine"
                    size="5"
                    className={`${serumIsShow ? 'form-control' : 'disabled-input'}`}
                    value={creatinineValue}
                    ref={inputSerumCreatinineRef}
                    min="0"
                    onChange={(event) => {
                      setCreatinineValue(event.target.value);
                      setErrors((prev) => ({ ...prev, creatinine: '' }));
                    }}
                    disabled={!serumIsShow}
                  />
                </div>
                <div className="col-xs-3" style={{ paddingLeft: "20px" }}>
                  <div className='row'>
                    micromoles/L
                  </div>
                </div>
               
              </div>
            </div>


            <div className='row'>
              <div className="col-xs-4" />
              <div className="col-xs-7">
                {errors.creatinine && <div className="invalid-feedback">{errors.creatinine}</div>}
              </div>
            </div>
            <div className='row'>
              <div className="col text-center">
                <label className="control-label no-wrap" >OR</label>
              </div>
            </div> 
            <div className="form-group mTop12px row">
              <div className="col-lg-2 d-none d-lg-block" />
              <div className="col-xs-2">
              </div>
              <div className="col-lg-7 col-xs-9">
                <div className='row'>
                  <div className="col-xs-1 reduce-padding">
                    <input
                      className="mgrt5px mgtp2px big-checkbox"
                      type="checkbox"
                      checked={renalUnknown}
                      // ref={inputWeightRef}
                      onChange={
                        (event) => {
                          setRenalUnknown(event.target.checked);
                          setCreatinineValue('');
                          setErrors((prev) => ({ ...prev, creatinine: '' }));
                          setSerumIsShow(!serumIsShow);
                        }
                      }
                    />
                  </div>
                        
                  <div className="col-xs-11">
                    Kidney function is unknown, but <strong>not</strong> presumed to be impaired
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group mTop12px" style={{ display: 'none' }}>
              <select className="form-control" name="SerumUnit" defaultValue={serumUnit}>
                <option value="0.001|0">micromoles/L</option>
                <option value="1|0">millimoles/L</option>
              </select>
            </div>
            <div className="row form-group mTop12px">
              <div className="col-xs-12 btncalc-container" style={{ display: 'flex', justifyContent: 'center' }}>
                <button className="btn btn-info btn-sm borderNone btn-calc btn-custom" onClick={() => calculate()}>Calculate</button>
                <button className="btn btn-default btn-sm borderNone btn-clr btn-custom" onClick={() => reset()}>Clear all</button>
              </div>
            </div>
          </div>
            <>
              <div className="col-xs-12 col-lg-6 aid-result" style={{ background: 'wheat', height: '100%' }}>
                <div className='text-center'>
                  <label htmlFor='initialDose'><strong>Dose</strong></label>
                </div>
                <div className="mgtp10px">
                  <input
                    type="text"
                    className="form-control aid-dose-width center text-center"
                    value={initialDose}
                    readOnly
                  />
                </div>
                <div className="mgtp10px">
                  <p><strong>Note: </strong>This calculator does not replace clinical judgement. Consider the appropriateness of the calculated dose for the patient.</p>
                </div>
                <div className="mgtp10px">
                  
                  <p dangerouslySetInnerHTML={{ __html: adviseFrequency }}></p>
                </div>
                <div className="mgtp10px">
                  {dosingFrequency && <strong>Dosing frequency</strong>}
                  <p dangerouslySetInnerHTML={{ __html: dosingFrequency }}></p>
                </div>
              </div>
              {
                dosingDerivation && <div className="col-xs-12 col-lg-6 aid-result" style={{ background: 'wheat', height: '100%', marginTop: '20px' }}>
                <div className="mgtp10px">
                  <strong>How this dose was derived</strong>
                  <p dangerouslySetInnerHTML={{ __html: dosingDerivation }}></p>
                </div>
                </div>
              }
            </>
        </div>
      </div>
    </div>
  )
}

export default GidCalc;
