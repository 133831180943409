import { useMsal } from '@azure/msal-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { loginRequest } from '../authConfig';

const Footer = () => {
  const { instance } = useMsal();
  

  return (
  <footer role="contentinfo" id="dart-footer">
    <div className="footer-bottom hidden-print">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12 text-left" data-weblinks-maxdepth="2">
            <ul className="row list-unstyled">
              <li>
                <nav aria-label="TG Footer Parent">
                  <ul className="list-unstyled">

                    <li className="tgfooternav">
                      <a href="https://tg.org.au/conditions-of-use-and-disclaimer">Conditions of use and disclaimer</a>
                    </li>

                    <li className="tgfooternav">
                      <a href="https://tg.org.au/privacy-policy">Privacy policy</a>
                    </li>

                    <li className="tgfooternav">
                      <a href="https://tg.org.au/license-agreement">Licence agreement</a>
                    </li>

                    <li className="tgfooternav">
                      <a href="https://tg.org.au/copyright-permission">Copyright permission</a>
                    </li>

                    <li className="tgfooternav">
                      <a href="https://tg.org.au/faq">Support and FAQ</a>
                    </li>

                    <li>
                      <div id="hiddenlogin" style={{ display: 'none' }}
                      onClick={() => {
                        instance.loginRedirect(loginRequest).catch(e => {
                          console.error(e);
                        });
                      }} >Login</div>
                    </li>
                  </ul>
                </nav>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="container tgcopyright">
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12 text-left">
          <div className="xrm-editable-html xrm-attribute" data-languagecontext="English">
            <div className="xrm-attribute-value">
              <p><br/>
                <span style={{ fontSize: '10px', fontFamily: 'DINNeuzeitGrotesk-Light, sans-serif' }}>
                  <span>&zwnj;© </span>2023
                  <span style={{ fontSize: '10px', fontFamily: 'DINNeuzeitGrotesk-Light, sans-serif' }}>&nbsp;Therapeutic Guidelines Limited. All rights reserved<br/>&zwnj;ABN 45 074 766 224<br/>&zwnj;</span></span>
              </p>
              <span style={{ fontSize: '10px', fontFamily: 'DINNeuzeitGrotesk-Light, sans-serif' }}></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);}

export default Footer;
