/* eslint-disable */
import React, { useState, useEffect, Suspense } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

const NavBar = React.lazy(() => import("../../components/navBar"));
const TopicBanner = React.lazy(() => import("../../components/topicBanner"));
const Footer = React.lazy(() => import("../../components/footer"));


const SuccessReceipt = ({ orderDetails, formatDate }) => (
  console.log("CheckoutConfirmation.jsx, orderDetails",orderDetails),
  <div className="panel panel-default">
    <br />
    <p>
    Thank you for your subscription. Your receipt will be emailed to you for your records.
    </p>
    <br />
    <h3>
      <strong>Therapeutic Guidelines Limited</strong>
    </h3>
    <div className="panel-body">
      <div className="row">
        <div className="col-xs-4 text-left receipt-left">
          <h4>
            <strong>Receipt</strong>
          </h4>
          <br />
          <div>
            <p>
              <span>{orderDetails.contactInfo.title} </span>
              {orderDetails.contactInfo.firstName}{" "}
              {orderDetails.contactInfo.lastName}
            </p>
            {orderDetails.contactInfo.company && (
              <p>{orderDetails.contactInfo.company}</p>
            )}
            <p>{orderDetails.contactInfo.address.street}</p>
            <p>{orderDetails.contactInfo.address.city}</p>
            <p>
              {orderDetails.contactInfo.address.state}{" "}
              {orderDetails.contactInfo.address.postalCode}
            </p>
            <p>{orderDetails.contactInfo.address.country}</p>
          </div>
        </div>
        <div className="col-xs-4 text-left receipt-middle">
          <p>
            <strong>Receipt Date:</strong>
          </p>
          <p className="mb-20px">
            {formatDate(orderDetails.receiptDetails.paymentDate)}
          </p>
          <p>
            <strong>Receipt Number:</strong>
          </p>
          <p className="mb-20px">
            {orderDetails.receiptDetails.receiptNumber}
          </p>
          <p>
            <strong>Purchase Order No:</strong>
          </p>
          <p className="mb-20px">
            {orderDetails.receiptDetails.purchaseOrderNumber}
          </p>
          <p>
            <strong>ABN:</strong>
          </p>
          <p className="mb-20px">45 074 766 224</p>
        </div>
        <div className="col-xs-4 text-right receipt-right">
          <h5>
            <strong>Therapeutic Guidelines Limited</strong>
          </h5>
          <p>Ground Floor, 473 Victoria Street</p>
          <p>West Melbourne VIC 3003</p>
          <p>Australia</p>
        </div>
      </div>
      <table className="receipt-table">
        <thead>
          <tr>
            <th>Description</th>
            <th className="text-right">Price AUD</th>
          </tr>
        </thead>
        <tbody>
          {orderDetails.items.map((item, index) => (
            <tr key={index}>
              <td>
                {item.description} -{item.quantity} licence(s)
                <p>
                  Valid to (inclusive): {formatDate(item.validTo)}
                </p>
              </td>
              <td className="text-right">
                ${item.price.toFixed(2)}
              </td>
            </tr>
          ))}
          <tr className="total-row">
            <td>
              <strong>Total AUD (inc GST)</strong>
            </td>
            <td className="text-right">
              <strong>
                ${orderDetails.payment.total.toFixed(2)}
              </strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

const ErrorReceipt = ({ orderDetails }) => (
  <div className="col-lg-6 col-md-8 col-sm-10 col-xs-12 confirmation-box">
    <div className="alert alert-danger">
      <h4 className="alert-heading">Payment Failed</h4>
      <p>
      Unfortunately, there was an error processing your order.
      </p>
      <hr />
      <p className="mb-0">
         Please contact our customer service team using the online form link    <a
          href="https://www.tg.org.au/contact-us/"
          className="alert-link"
          title="Contact Us"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Support
        </a> and quoting your&nbsp;
         {orderDetails?.referenceNumber && (
          <span>
            <strong> Transaction Reference:</strong> {orderDetails?.referenceNumber}
          </span>
        )}
           {orderDetails?.orderNumber && (
        <span> and&nbsp;
          <strong>Order Number:</strong> {orderDetails.orderNumber}
        </span>
        )}      
      </p>  
    </div>
  </div>
);

const BackendErr = ({ orderDetails }) => (
  <div className="col-lg-6 col-md-8 col-sm-10 col-xs-12 confirmation-box">
    <div className="alert alert-danger">
      <h4 className="alert-heading">Payment Received, Order Processing Issue.</h4>     
      <hr />
      <p>
      We have received your payment, but encountered an issue while processing your order. Please reach out to our support team via the online form link <a
          href="https://www.tg.org.au/contact-us/"
          className="alert-link"
          title="Contact Us"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Support
        </a>, and provide your payment reference:{orderDetails?.paymentTransactionID}. We apologise for any inconvenience this may have caused.
      </p> 
    </div>
  </div>
);

const CheckoutConfirmation = (props) => {
  const { pathname, search, history, location } = props;
  const [orderDetails, setOrderDetails] = useState(null);

  useEffect(() => {
    if (location.state?.state?.orderDetails) {
      setOrderDetails(location.state.state.orderDetails);
    } else {
      history.push("/");
    }
  }, [location.state, history]);

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleDateString("en-AU", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  if (!orderDetails) return null;

  return (
    <Suspense fallback={<div className="loader"></div>}>
      <NavBar push={push} />
      <TopicBanner pathname={pathname} search={search} push={push} />
      <div className="container purchase-receipt">
        {orderDetails.success === false ? (
          orderDetails.backendstatus === true?
          <ErrorReceipt orderDetails={orderDetails} />: <BackendErr orderDetails={orderDetails} />
        ) : (
          <SuccessReceipt orderDetails={orderDetails} formatDate={formatDate} />
        )}
      </div>
      <Footer />
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

export default connect(mapStateToProps, { push })(CheckoutConfirmation);