import { useState, useEffect } from 'react';

function isMobileOrTablet() {
  if (typeof navigator === 'undefined') {
    return false;
  }
  
  const ua = navigator.userAgent.toLowerCase();
  const basicCheck = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile|tablet|samsungbrowser/i.test(ua);
  const isOldIPad = /ipad/.test(ua);
  const isNewIPad = /macintosh/.test(ua) && 'ontouchstart' in document;
  return basicCheck || isOldIPad || isNewIPad;
}

function useIsWebScreen() {
  const [isWebScreen, setIsWebScreen] = useState(() => !isMobileOrTablet());

  useEffect(() => {
    const handleResize = () => {
      setIsWebScreen(!isMobileOrTablet());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isWebScreen;
}

export default useIsWebScreen;