import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useMsal } from '@azure/msal-react';
import { initiateOpenAthensLogin, loginRequest, useDxp } from '../authConfig';
import { updateUser } from '../features/counter/counterSlice';
import { useDispatch, useSelector } from 'react-redux';
import ForgotPasswordInMps from './forgotPasswordInMps';
import { isPWAInstalled } from '../features/search/util';

const LoginModal = (props) => {
  const { instance } = useMsal();
  const [loggingIn, setLoggingIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showInactiveAlert, setShowInactiveAlert] = useState(false);

  const showAccessKey = useSelector((state) => state.counter.showAccessKey);

  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('login')) { document.body.style.display = 'none'; }
    if (params.get('login') === 'true') { handleLogin(); }

    const datalist = document.getElementById('institutions');
    // console.log(window.tgc);
    // TODO: Restore here or in login... but somewhere.
    Object.keys(window.tgc).map((key) => {
      const option = document.createElement("option");
      const currentValue = atob(window.tgc[key])
      option.title = currentValue;
      option.value = currentValue;
      // datalist.appendChild(option);
    });

  }, []);

  const handleCloseModal = () => {
    let tglogin = document.getElementById('tglogin');
    let modalBackdrop = document.querySelector('.modal-backdrop');

    if (tglogin.classList.contains('in')) {
      tglogin.classList.toggle('in');
      setTimeout(() => {
        tglogin.style.display = 'none';
        if (modalBackdrop === undefined) {
          modalBackdrop.style.display = 'none';
          modalBackdrop.classList.toggle('in');
        }
      }, 100);
    }
  }

  const handleLogin = () => {
    window.history.pushState({}, '', '/');
    instance.loginPopup(loginRequest).catch(e => {
      console.error(e);
    });

  };

  const LoginUser = async () => {
    const username = document.getElementById('username').value;
    const password = document.getElementById('password').value;

    // const html = `	        <div class="col-lg-9 col-xs-12 col-sm-8 hidden-xs pull-right text-right">
    //       	<div class="btn-group" id="fullAccess">
    //        <button class="btn btn-info noborder text-left dropdown-toggle btn-block" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-expanded="true"> <span class="pull-left">My account</span> <span class="fa fa-chevron-down pull-right"></span> </button>
    //           <ul class="dropdown-menu dropdown-menu-right" id="purchaseLicence" role="menu" aria-labelledby="dropdownMenu1">
    //            <li role="presentation"><a href="/showMyProfile" tabindex="-1" role="menuitem"><strong>
    //                <!-- Individual user will always see full name if he is not primary user -->
    //                  Peter&nbsp;Chen</strong></a></li>
    //             <!-- For child user there will be no link -->
    //               <li role="presentation"><a href="/etgAccess" tabindex="-1" role="menuitem">Therapeutic Guidelines online</a></li>
    //             <li class="divider"></li>
    //             <li role="presentation"><a href="/userlogout" tabindex="-1" role="menuitem">Log out</a></li>
    //           </ul>
    //         </div>
    //       </div>`;

    //       const parser = new DOMParser();
    //       const doc = parser.parseFromString(html, 'text/html');

    //       const menuList = doc.querySelector('#purchaseLicence[role="menu"][aria-labelledby="dropdownMenu1"]');
    //       let index = 0;
    //       let authIDu = {};
    //       if (menuList) {
    //         Array.from(menuList.querySelectorAll('li')).map(li => {
    //           const link = li.querySelector('a');
    //           if (!link) return { type: 'divider' };

    //           if (index === 0) {
    //             authIDu['given_name'] = link.textContent.trim();
    //           } else if (index === 1) {
    //             authIDu['i'] = link.textContent.trim();
    //           }
    //           index++;
    //         });
    //         dispatch(updateUser(authIDu));
    //       }
    //   document.cookie = `authIDu=${JSON.stringify(authIDu)}; path=/`;

    setLoggingIn(true);
    setIsLoading(true); // Add loading state

    console.log('Starting 30 second login timer...');
    const timeoutId = setTimeout(() => {

      console.log('Login timed out after 30 seconds, refreshing page...');
      setIsLoading(false); // Reset loading on timeout
      window.location.reload();
    }, 30000);
    try {
      let response = await fetch('https://tgldcdp.tg.org.au/userlogin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: "include",
        body: new URLSearchParams({
          "username": username,
          "password": password,
          "ajaxsubmitted": "yes",
        }).toString()
      });

      let html = await response.text();
      if (html === 'fail') {
        alert('Invalid username or password');
        setLoggingIn(false);
        setIsLoading(false); // Reset loading on failure
        clearTimeout(timeoutId);
        return;
      }      

      if (html === 'inactive' ||response.status === 405) {
        setShowInactiveAlert(true);
        setLoggingIn(false);
        setIsLoading(false); // Reset loading on inactive
        clearTimeout(timeoutId);
        return;
      }
      
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');

      const menuList = doc.querySelector('#purchaseLicence[role="menu"][aria-labelledby="dropdownMenu1"]');
      let index = 0;
      let authIDu = { email: username  };

      if (menuList) {
        Array.from(menuList.querySelectorAll('li')).map(li => {
          const link = li.querySelector('a');
          if (!link) return { type: 'divider' };

          if (index === 0) {
            authIDu['given_name'] = link.textContent.trim();
          } else if (index === 1) {
            authIDu['i'] = link.textContent.trim();
          }
          index++;
        });
        dispatch(updateUser(authIDu));
        handleCloseModal(); 
        setLoggingIn(false);
      }
      clearTimeout(timeoutId);
      setIsLoading(false); // Reset loading on success
   
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false); // Reset loading on error
      clearTimeout(timeoutId);
    }
    // handleCloseModal();
    // setLoggingIn(false);
  };

  const handleRefreshLogin = async (e) => {
    e.preventDefault();
    const username = document.getElementById('username').value;
    const password = document.getElementById('password').value;
    
    setLoggingIn(true);
    try {
      await fetch('https://tgldcdp.tg.org.au/refereshlogin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: "include",
        body: new URLSearchParams({
          "username": username,
          "password": password,
        }).toString()
      });

      setShowInactiveAlert(false);
    } catch (error) {
      console.error('Error during refresh login:', error);
    } finally {
      setLoggingIn(false);
    }
  };

  function checkReferalToken() {
    const referralToken = document.getElementById('referralToken').value;
    let decodedToken;
    if (referralToken) {
      try {
        decodedToken = JSON.parse(atob(referralToken));
        if ('exp' in decodedToken && decodedToken.exp < Date.now()) {
          alert('Token expired');
          return;
        }
      } catch (error) {
        alert('Invalid token');
        return;
      }
      localStorage.setItem('tgt', referralToken);
      window.dataLayer = window.dataLayer || [];
          
      window.dataLayer.push({
        'event': 'login',
        'institution': decodedToken.name,
        'origin': 'access-key'
      });

      alert('Access key set');
      window.location.reload();
    }
  }
  const handleForgotPasswordClick = (e) => {
    e.preventDefault();
    setShowForgotPassword(true);
  };

  const handleCloseForgotPassword = () => {
    setShowForgotPassword(false);
  };

  return createPortal(
    <>
      <div id="tglogin" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={handleCloseModal} data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title">Log In</h4>
            </div>
            <div id="tgloginid" className="modal-body" style={{ maxHeight: '650px', overflowY: 'auto', paddingLeft: '30px', paddingRight: '30px' }}>
              {
               !useDxp && !showForgotPassword && !showAccessKey && <>

                  <div className="list-group">
                    <div className="jumbotron white-jumbo">
                      <h4 className="list-group-item-heading">
                        <i className="fa fa-user-circle"></i> &nbsp;
                        Log in or Sign up
                      </h4>
                      {showInactiveAlert && (
                          <div className="alert alert-danger" id="inactiveusrpwd">
                            You are already logged in, please <button onClick={handleRefreshLogin} className="btn-link underline p-0 border-0 bg-transparent" style={{ color: '#0000EE' }}>click here</button> to cancel that session.
                          </div>
)}
                      Log in with an email address and password or create a new account <br />
                      Click the button below to log in securely using an email address or a preferred identify provider. You can Sign Up for an account if you do not have one.
                      <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '15px' }}>
                        <input type="text" className="form-control input-lg"
                          id="username" placeholder="Enter your username" autoComplete='off' required style={{ marginBottom: '10px' }} />
                        <input type="password" className="form-control input-lg"
                          id="password" placeholder="Enter your password" autoComplete='off' required />
                      </div>

                      <button type='button' className="btn btn-primary" style={{ height: '43px', fontSize: '20px', width: '100%', marginTop: '10px' }}
                        disabled={loggingIn || isLoading }
                        onClick={LoginUser}>
                        {isLoading ? (
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <span 
                              className="fa fa-spinner fa-spin" 
                              style={{ marginRight: '8px' }}
                            ></span>
                            Loading...
                          </div>
                        ) : 'Log in'}</button>
                        <p style={{ marginTop: '15px', fontSize:'15px' }}>
                        Forgot your password? <a href="#" onClick={handleForgotPasswordClick} className="underline">Click here</a>
                      </p>
                    </div>
                  </div>
                  {/* <div className="list-group">
                    <div className="jumbotron white-jumbo">
                      <h4 className="list-group-item-heading">
                        <i className="fa fa-university"></i> &nbsp;
                        Access through your institution
                      </h4>
                      Login with instructions provided by your institution/organisation.
                      <button type='button' className="btn btn-primary" style={{ height: '43px', fontSize: '20px', width: '100%', marginTop: '10px' }}
                        onClick={initiateOpenAthensLogin}>
                        OpenAthens</button>
                      <div id="selectedInstitution"></div>
                    </div>
                  </div> */}
                </>
              }
               {
                !useDxp && showForgotPassword && (
                  <ForgotPasswordInMps onClose={handleCloseForgotPassword} />
                )
              }
              {isPWAInstalled() && showAccessKey && <div className="list-group">
                <div className="jumbotron white-jumbo">
                  <h4 className="list-group-item-heading">
                    <i className="fa fa-info-circle"></i> &nbsp;
                    Access key
                  </h4>
                  <div className="input-group input-group-lg" style={{ marginTop: '10px' }}>
                    <input type="email" className="form-control input-lg" id="referralToken"
                      placeholder="Access key" autoComplete='off' required />
                    <span className="input-group-addon" style={{ cursor: 'pointer' }} onClick={checkReferalToken}>
                      Submit
                    </span>
                  </div>
                  <div id="selectedInstitution"></div>
                </div>
              </div>}
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById('portal')
  )
};



export default LoginModal;
