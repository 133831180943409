import React from 'react'
import { useDispatch } from "react-redux";

import {
  updateState
} from "../../features/counter/counterSlice";

const Menu = (props) => {
  const dispatch = useDispatch();

  const openLinks = (guidelineName) => {
    const ftxtcontenturl = `/viewTopic/?guidelinePage=${encodeURIComponent(
      guidelineName
    )}&etgAccess=true`;
    const guidelinePage = guidelineName.replace(/ /g, "_");
    const data = {
      ditaPath: `${guidelinePage}/`,
      queryString: {
        guidelinePage: encodeURIComponent(guidelineName),
        etgAccess: "true",
      },
      displayBanner: true,
    };
    dispatch(updateState(data));
    props.toggleMobileMenu();
    props.push(ftxtcontenturl);
  };


  return (
    <ul className="dropdown-menu" role="menu">
      <li role="none">
        <a
          role="menuitem"
          aria-label="Addiction Medicine"
          href="#"
          title="Addiction Medicine"
          onClick={() => openLinks("Addiction Medicine")}
        >
          Addiction
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Antibiotic"
          href="#"
          title="Antibiotic"
          onClick={() => openLinks("Antibiotic")}
        >
          Antibiotic
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Bone and Metabolism"
          href="#"
          title="Bone and Metabolism"
          onClick={() => openLinks("Bone and Metabolism")}
        >
          Bone and Metabolism
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Cardiovascular"
          href="#"
          title="Cardiovascular"
          onClick={() => openLinks("Cardiovascular")}
        >
          Cardiovascular
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Dermatology"
          href="#"
          title="Dermatology"
          onClick={() => openLinks("Dermatology")}
        >
          Dermatology
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Developmental Disability"
          href="#"
          title="Developmental Disability"
          onClick={() =>
            openLinks("Developmental Disability")
          }
        >
          Developmental Disability
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Diabetes"
          href="#"
          title="Diabetes"
          onClick={() => openLinks("Diabetes")}
        >
          Diabetes
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Fatigue"
          href="#"
          title="Fatigue"
          onClick={() => openLinks("Fatigue")}
        >
          Fatigue
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Gastrointestinal"
          href="#"
          title="Gastrointestinal"
          onClick={() => openLinks("Gastrointestinal")}
        >
          Gastrointestinal
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Liver Disorders"
          href="#"
          title="Liver Disorders"
          onClick={() => openLinks("Liver Disorders")}
        >
          Liver Disorders
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Neurology"
          href="#"
          title="Neurology"
          onClick={() => openLinks("Neurology")}
        >
          Neurology
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Oral and Dental"
          href="#"
          title="Oral and Dental"
          onClick={() => openLinks("Oral and Dental")}
        >
          Oral and Dental
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Pain and Analgesia"
          href="#"
          title="Pain and Analgesia"
          onClick={() => openLinks("Pain and Analgesia")}
        >
          Pain and Analgesia
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Palliative Care"
          href="#"
          title="Palliative Care"
          onClick={() => openLinks("Palliative Care")}
        >
          Palliative Care
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Psychotropic"
          href="#"
          title="Psychotropic"
          onClick={() => openLinks("Psychotropic")}
        >
          Psychotropic
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Respiratory"
          href="#"
          title="Respiratory"
          onClick={() => openLinks("Respiratory")}
        >
          Respiratory
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Rheumatology"
          href="#"
          title="Rheumatology"
          onClick={() => openLinks("Rheumatology")}
        >
          Rheumatology
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Sexual and Reproductive Health"
          href="#"
          title="Sexual and Reproductive Health"
          onClick={() =>
            openLinks("Sexual and Reproductive Health")
          }
        >
          Sexual and Reproductive Health
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Toxicology and Toxinology"
          href="#"
          title="Toxicology and Toxinology"
          onClick={() =>
            openLinks("Toxicology and Toxinology")
          }
        >
          Toxicology and Toxinology
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Ulcer and Wound Management"
          href="#"
          title="Ulcer and Wound Management"
          onClick={() =>
            openLinks("Ulcer and Wound Management")
          }
        >
          Ulcer and Wound Management
        </a>
      </li>
      <li role="none">
        <a
          role="menuitem"
          aria-label="Wilderness Medicine"
          href="#"
          title="Wilderness Medicine"
          onClick={() => openLinks("Wilderness Medicine")}
        >
          Wilderness Medicine
        </a>
      </li>
    </ul>
  )
}

export default Menu