import React, { useState, useEffect, useRef } from "react";
import { paymentApi } from "../../../api/paymentApi";
import { withRouter } from "react-router-dom";

const PayPalLoginButton = (props) => {
  const { history, amount, onPayment, onError } = props;
  const [sdkReady, setSdkReady] = useState(false);

  const paypalButtonsRef = useRef(null);
  const currency = "AUD";

  useEffect(() => {
    const loadPayPalScript = async () => {
      try {
        const clientId = await paymentApi.getPayPalClientId();
        const script = document.createElement("script");
        script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${currency}&disable-funding=card`;
        script.async = true;
        script.onload = () => setSdkReady(true);
        document.body.appendChild(script);
      } catch (error) {
        console.error("Error loading PayPal SDK:", error);
      }
    };
    loadPayPalScript();
  }, [currency]);

  useEffect(() => {
    if (sdkReady) {
      paypalButtonsRef.current = window.paypal
        .Buttons({
          createOrder: async () => {
            try {
              const orderId = await paymentApi.createPayPalOrder(
                amount,
                currency
              );
             return orderId;
            } catch (error) {
              console.error("Error creating PayPal order:", error);
              throw error;
            }
          },
          onApprove: async (data, actions) => {
            try {
              const response = await paymentApi.capturePayPalOrder(
                data.orderID
              );
              //const response = await paymentApi.capturePayPalOrder("FAKE_INVALID_ORDER_ID");
              //console.log("Payment completed successfully", response);
              if (response.success) {
                onPayment({
                  paymentType: "Paypal",
                  transactionId: response.transactionDetails.id,
                  status: "success",
                  amount: amount,
                  cardType: "PAYPAL",
                });
              } else {
                onError({
                  type: "paypal",
                  code: response.errorCode,
                  message: response.message
                });
              }
            } catch (error) {
              console.error("Error capturing PayPal order:", error);
              const statusCode = error?.response?.status || 500;
              const errorName = error?.response?.data?.name || "CAPTURE_ERROR";
          
              onError({
                type: "paypal",
                code: errorName,
                message: "Failed to capture PayPal payment. Please try again later.",
              });
            }
          },
        })
        .render("#paypal-button-container");
    }
  }, [sdkReady, amount, currency, history]);

  return (
    <div className="paypal-button-container">
      {sdkReady && <div id="paypal-button-container"></div>}
      <p className="">The safer, easier way to pay</p>
    </div>
  );
};

export default withRouter(PayPalLoginButton);
