import React, { useState } from 'react';

const ForgotPasswordInMps = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!email || !email.includes('@')) {
      setErrorMessage('Please enter valid email Id.');
      setShowError(true);
      return;
    }
    setIsSubmitting(true); 
    try {
      const response = await fetch('/api/forgotPassword',  {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify({ username: email })
        });
  
        if (!response.ok) {
          throw new Error('Failed to process request');
        }
  
        const data = await response.text();
        console.log('data:', data);
        if (data === 'forgotpwdsuccess') {
          setShowConfirmation(true);
          // onClose();
        } else{
          setErrorMessage('An error occurred. Please try again later. If the problem persists, please contact support.');
          setShowError(true);
        }
      } catch (error) {
        console.error('Error:', error);
        setErrorMessage('An error occurred. Please try again later. If the problem persists, please contact support.');
        setShowError(true);
      }finally {
        setIsSubmitting(false);
      }
  };

  return (
    <div id="forgotBox" className="forgot-password-container">
      <div className="forgot-header">
      <h4 className="login-heading">Forgot password</h4>
     <button 
          type="button" 
          className="close" 
          onClick={onClose}
          style={{ position: 'absolute', right: '15px', top: '15px' }}
        > 
        <span aria-hidden="true">×</span>
        </button> 
      </div>

      {showError && (
        <div className="alert alert-danger alert-dismissable">
          <a 
            href="#" 
            className="close" 
            onClick={(e) => {
              e.preventDefault();
              setShowError(false);
            }}
          >
            ×
          </a>
          {errorMessage}
        </div>
      )}

{showConfirmation ? (
        <div id="confirmation" style={{background:"#dededf", padding: "15px", borderRadius: "4px"}}>
          <p>
            If this email address is registered in our system, we will send you an email with 
            instructions to reset your password. The email may take a few minutes to arrive. 
            If you do not receive the email within 15 minutes, please check your spam and junk folders.
          </p>
        </div>
      ) : (
        <form 
          name="forgotForm" 
          id="forgotpwd" 
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="emailAddress"
              name="user.email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
              required
            />
          </div>
          <button 
            type="submit" 
            className="btn btn-primary"
            style={{ width: '100%' }}
            disabled={isSubmitting}
          >
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default ForgotPasswordInMps;