import $ from 'jquery'
import { fetchContent } from '../counter/counterSlice';

const guideline = 'quicklinks/';
const topicFile = 'patient_information_pdfs.dita';
let bfditamap = "https://ccmsfiles.tg.org.au/s6/quicklinks/Therapeutic_Guidelines_Drug_use_in_breastfeeding.ditamap";
let pbditamap = "https://ccmsfiles.tg.org.au/s6/quicklinks/Therapeutic_Guidelines_Drug_use_in_pregnancy.ditamap";
let ditaMapLength = 10;
let ditaIndex = 0;

function cleanDITA(ditaxml){
    try{ditaxml = new XMLSerializer().serializeToString(ditaxml);}catch(e){}
   try {
       ditaxml = ditaxml.replace('<?xml version="1.0" encoding="UTF-8"?>',"");
       ditaxml = ditaxml.replace('<!DOCTYPE concept PUBLIC "-//OASIS//DTD DITA Concept//EN" "concept.dtd">',""); 
       ditaxml = ditaxml.replace('<!DOCTYPE map PUBLIC "-//OASIS//DTD DITA Map//EN" "map.dtd">',""); 
       
       ditaxml = ditaxml.replace(/<title/g,"<ditatitle");
       ditaxml = ditaxml.replace(/\/title>/g,"/ditatitle>");
   
       //For tables
       ditaxml = ditaxml.replace(/<row/g,"<tr");
       ditaxml = ditaxml.replace(/\/row>/g,"/tr>");
       ditaxml = ditaxml.replace(/<entry/g,"<td");
       ditaxml = ditaxml.replace(/\/entry>/g,"/td>");
       ditaxml = ditaxml.replace(/nameend="col/g,'colspan="');
       ditaxml = ditaxml.replace(/morerows="/g,'rowspan="'); // needs to be +1. Set in code.
       ditaxml = ditaxml.replace(/href\=\"\.\.\/\.\.\/images/g,'src="https://ccmsfiles.tg.org.au/s6/images');
       ditaxml = ditaxml.replace(/Images/g,'src="images');

   } catch (error) {
    //    console.log(fileURL);
       console.log(window.location.href,error);
       console.log(ditaxml);
   }
   return ditaxml;
}

export function PatientInfo(ditaHTML,dartSelectors){
   $("#patientId table").hide()
   $(ditaHTML).find("table").each(function(index,element){
       $("#patientId").append(element);
   });
   ProcessPdfXref(dartSelectors);
   
}


function ProcessDita(dita,elementHref){
   elementHref = elementHref.replace("https://ccmsfiles.tg.org.au/s6/quicklinks/","");
   $('#pbcontentId .bodyContentDiv').find('[href="'+elementHref+'"]').prepend($(dita).html());
   ditaIndex++;
   // console.log(">>>>>",ditaMapLength,ditaIndex);
   if(ditaMapLength===ditaIndex){
       setTimeout(() => {ProcessXref();}, 1000);
   }


}

function ProcessPdfXref(dartSelectors){
   $("xref").before('<i class="fa fa-file-pdf-o red"></i> ');
   $("xref").on({
       click:function(){
           let selectedXref = this;
           let pdfLink = $(selectedXref).attr("href").replace("../PDF","https://ccmsfiles.tg.org.au/s6/PDF");
            //    window.open(pdfLink);
               let tgPdfViewer = document.querySelector('#tgpdfviewer');
               let tgPDFIframe = document.querySelector('#tgpdfviewer iframe');
               let modalBackdrop = document.querySelector('.modal-backdrop');
               // modalBackdrop.style.display = 'block';
               if(!tgPdfViewer.classList.contains('in')){
                 tgPdfViewer.classList.toggle('in');
                 tgPDFIframe.src = pdfLink;
                 let pdfFileName = (pdfLink.indexOf("/")===-1)?pdfLink:pdfLink.split("/").slice(-1)[0];
                 dartSelectors.iDB.read(pdfFileName).then((value) => {
                   if (value) {
                     pdfLink = value;
                   }
                //    console.log(pdfLink);
                   tgPdfViewer.style.display = 'block';
                   if(modalBackdrop===undefined){
                     modalBackdrop.style.display = 'block';
                     modalBackdrop.classList.toggle('in');
                   }
                 });
               }
       }
   });
}

function ProcessXref(){
   $("xref").on({
       click:function(){
           let selectedXref = this;
           let selectHref = $(selectedXref).attr("href");
           let selectScope= $(selectedXref).attr("scope");
           // let selectedText = $(selectedXref).text();
           // console.log(selectedText);
           if(selectScope==="external"){
               window.open(selectHref);
           }else{
            $('topicref[href*="'+selectHref+'"]')[0].scrollIntoView({behavior: 'smooth'});   
           }
       }
   });
   if(window.location.hash!=='')
       $('topicref [href*="'+window.location.hash.replace("#",'').replace("drug_bf_","")+'"]')[0].scrollIntoView();

   $('xref:empty').each(function(index,element){
       console.log($(element).attr("href"));
   });
}

function PBStyle(){
   $("body").append(`<style>
       category, prolog,[outputclass="tgl_supertitle_base"], #general_information_on_drug_use_in_breastfeeding_, concept#drug_use_in_pregnancy_ {
           display:none;
       }
       ditacontent {
           display:block;
       }
       note {
       background: #F5E0DB;
       color: #2f2f2f;
       font-size: 14px;
       padding: 6px;
       border: 1px solid transparent;
       border-radius: 4px;
       display: inline-block;
       margin-bottom: 20px;
   }
   concept>ditatitle {
       font-size: 36px;
   }
   xref {
       cursor: pointer;
   }
   #australian_categorisation_of_drugs_in_pregnancy{display:none;}
   </style>`);
}